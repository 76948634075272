* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

* {
  margin: 0;
  padding: 0;
}
